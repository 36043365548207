<template>
    <div class="about">
        <div class="page-head partners ther">
            <div class="container">
                <div
                    class="
                        partner-head-card
                        ther
                        d-flex
                        flex-column flex-sm-row
                        align-items-center
                    "
                >
                    <div class="mb-2 mb-sm-0">
                        <img
                            src="@/assets/imgs/partner.png"
                            alt="partner name"
                        />
                    </div>
                    <div>
                        <h4 class="font-weight-600 text-brand">
                            Allergy and immunology
                        </h4>
                        <p>
                            Contrary to popular belief, Lorem Ipsum is not
                            simply random text. It has roots in a piece of
                            classical Latin literature from 45 BC, making it
                            over 2000 years old.
                        </p>
                    </div>
                </div>
            </div>
        </div>
        <div class="page-content partners-page-content pt-4">
            <div class="container">
                <div
                    class="
                        d-flex
                        justify-content-center justify-content-sm-end
                        mb-4
                    "
                >
                    <select v-model="sortBy" 
                    @change="getAllModules"
                    class="d-inline-block px-1 mb-1 form-control text-gray"
                    style="width: 150px; height: 40px; max-width: 100%;">
                        <option selected value="0">Sort by</option>
                        <option value="ASC">ASC</option>
                        <option value="DESC">DESC</option>
                    </select>
                </div>
                <div class="row pb-4">
                    <template v-if="loading">
                        <div v-for="(mod, index) in 4" :key="index" class="col-sm-6 col-lg-4 col-xl-3">
                            <div class="module-card mx-auto">
                                <div class="img mw-100 mb-3">
                                    <b-skeleton width="100%" height="9rem"></b-skeleton>
                                </div>
                                <ul class="d-flex flex-wrap justify-content-center justify-content-md-start align-items-center contains mb-3">
                                    <li v-for="(tag, index) in 2" :key="index">
                                        <div class="contains-item d-flex align-items-center">
                                            <b-skeleton width="3rem" height="1.2rem"></b-skeleton>
                                        </div>
                                    </li>
                                </ul>
                                <div class="progress mb-3">
                                    <b-skeleton width="45%" height="100%"></b-skeleton>
                                </div>
                                <h5 class="title text-capitalize">
                                    <b-skeleton width="8rem" height="1.1rem"></b-skeleton>
                                </h5>
                                <p class="sepciality text-capitalize">
                                    <b-skeleton width="6rem" height="0.8rem"></b-skeleton>
                                </p>
                                <div class="d-flex justify-content-between align-items-center mb-1">
                                    <div class="d-flex align-items-center">
                                        <b-skeleton class="img-circle" width="45px" height="45px"></b-skeleton>
                                        <small class="owner-name mx-2 text-capitalize">
                                            <b-skeleton width="5rem" height="0.7rem"></b-skeleton>
                                        </small>
                                    </div>
                                    <span class="price">
                                        <b-skeleton width="4rem" height="0.7rem"></b-skeleton>
                                    </span>
                                </div>
                            </div>
                        </div>
                    </template>
                    <template v-else-if="!loading && modules.length">
                        <div
                            v-for="module in modules"
                            :key="module.id"
                            class="col-sm-6 col-lg-4 col-xl-3">
                            <ModuleCard 
                            :cardData="module" 
                            @storedAsFavorite="getAllModules()"/>
                        </div>
                    </template>
                    <div v-else class="col-md-7 col-lg-6 mx-auto text-center mb-4">
                        <p class="no-modules mx-auto py-2">
                            {{ $t('noResults') }}
                        </p>
                    </div>
                </div>
                <!-- Pagination -->
                <div
                    v-if="lastPageID - 1 && !loading"
                    class="
                        d-flex
                        justify-content-center
                        py-4
                        mb-3
                        overflow-hidden
                    ">
                    <nav aria-label="Page navigation">
                        <ul class="pagination">
                            <li v-if="!isFirstPage" class="page-item">
                                <button 
                                @click="fetchPage('prev')"
                                class="page-link">
                                {{ $t('prev') }}
                                </button>
                            </li>
                            <li v-for="(page, index) in pagesLinks" :key="index"
                            class="page-item">
                                <button
                                    @click="fetchPage(page.id)"
                                    :class="[currentPage == page.id ? 'active' : '']"
                                    :disabled="currentPage == page.id"
                                    class="page-link">{{ page.id }}</button
                                >
                            </li>
                            <li v-if="!isLastPage" class="page-item">
                                <button 
                                @click="fetchPage('next')"
                                class="page-link">
                                {{ $t('next') }}
                                </button>
                            </li>
                        </ul>
                    </nav>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import ModuleCard from "@/components/ModuleCard";
import { getSpecialtyModules } from "@/API/specialties";

export default {
    name: "Ther",
    components: {
        ModuleCard,
    },
    data() {
        return {
            loading: true,
            sortBy: 0,
            pagesLinks: [],
            currentPage: 1,
            isFirstPage: true,
            isLastPage: false,
            lastPageID: 1,
            modules: [],
        };
    },
    computed: {
        ID() {
            return parseInt(this.$route.params.id);
        }
    },
    methods: {
        getAllModules() {
            this.loading = true;
            const params = {
                specialityID: this.ID
            }
            if (this.sortBy) params.sort = this.sortBy;

            getSpecialtyModules(params).then(res => {
                // console.log(res.data);
                if (res.status == 200) {
                    const response = res.data?.data?.module;
                    // console.log(response);

                    this.modules = response || []
                }

                this.loading = false;
            });
        }
    },
    mounted() {
        this.getAllModules()
    }
};
</script>
