<template>
    <div class="about">
        <div v-if="loading">
            <b-skeleton class="page-head mb-0" style="border-radius: 0 !important" width="100%" height="100%"></b-skeleton>
        </div>
        <div v-else class="page-head partner" :style="headerCoverStyle">
            <div class="container">
                <div class="partner-name d-flex align-items-center">
                    <img
                        class="p-logo"
                        :src="partner.image_logo"
                        :alt="partner.name"
                    />
                    <h1 class="font-weight-600 mb-0 text-capitalize">{{ partner.name }}</h1>
                </div>
            </div>
        </div>
        <div class="partner-head py-3 px-3">
            <div
                class="
                    container
                    d-flex
                    align-items-center
                    justify-content-between
                    flex-column flex-md-row
                "
            >
                <div class="d-flex align-items-center mb-3 mb-md-0">
                    <div class="d-flex align-items-center">
                        <span class="text-gray">Modules </span>
                        <span class="text-brand mx-2">
                            {{ partner.moduleCount || 0 }}
                        </span>
                    </div>
                    <div class="d-flex align-items-center mx-3">
                        <span class="text-gray">CME </span>
                        <span class="text-brand mx-2">
                            {{ partner.cme || 0 }}
                        </span>
                    </div>
                </div>
                <div class="d-flex align-items-center flex-column flex-sm-row">
                    <button 
                    @click="$store.commit('UPDATE_TERMS_MODAL', {
                        title: 'Terms and Conditions',
                        content: partner.term
                    });
                    $bvModal.show('terms-modal')"
                    class="btn bg-brand rounded-pill mx-2 mb-2 mb-sm-0">
                        <span class="mx-2">
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="26"
                                height="22"
                                viewBox="0 0 28.209 22"
                            >
                                <path
                                    d="M.166,16.494a3.643,3.643,0,0,0,7.224,0Zm19.683,7.334a.564.564,0,0,1-.563.566H8.761a.563.563,0,1,1,0-1.126H19.285A.562.562,0,0,1,19.849,23.827Zm-11.591.854h11.53V26.31H8.258Zm12.56-8.188a3.641,3.641,0,0,0,7.222,0Zm7.023-1.133-2.958-4.075h1.581a.7.7,0,0,0,0-1.4H18.088a4.148,4.148,0,0,0-3.179-2.67v-2.2a.7.7,0,0,0-.7-.7l-.065.007-.065-.007a.7.7,0,0,0-.7.7v2.2A4.146,4.146,0,0,0,10.2,9.881H1.819a.7.7,0,0,0,0,1.4H3.328L.37,15.359H0v.753H7.559v-.753H7.188L4.23,11.286H11.1a2.974,2.974,0,0,1,.041-.465.663.663,0,0,0,.041-.216,3,3,0,0,1,2.2-2.219v7.728h-.029a8.533,8.533,0,0,1-3.495,6.866h8.583a8.543,8.543,0,0,1-3.5-6.866H14.91V8.386a3,3,0,0,1,2.2,2.219.713.713,0,0,0,.042.216,3.128,3.128,0,0,1,.037.465H23.98L21.021,15.36h-.369v.753h7.557V15.36Zm-24.364,0H1.117l2.361-3.253V15.36Zm.6,0V12.108L6.444,15.36H4.082Zm20.046,0H21.767l2.362-3.253Zm.6,0V12.108l2.364,3.253ZM4.48,8.9a.7.7,0,1,1-.7-.7A.7.7,0,0,1,4.48,8.9Zm20.651-.037a.7.7,0,1,1-.342-.631A.7.7,0,0,1,25.131,8.864Z"
                                    transform="translate(0 -4.311)"
                                    fill="#fff"
                                />
                            </svg>
                        </span>
                        <span class="mx-2 font-weight-normal text-white">
                            Terms and Conditions
                        </span>
                    </button>
                    <button
                        @click="
                        $store.commit('UPDATE_TERMS_MODAL', {
                            title: 'Disclaimer',
                            content: partner.disclaimer
                        });
                        $bvModal.show('terms-modal')"
                        class="
                            btn
                            bg-brand
                            font-weight-normal
                            rounded-pill
                            text-white
                            mx-2
                            px-4
                        "
                    >
                        Disclaimer
                    </button>
                </div>
            </div>
        </div>
        <div class="pt-4">
            <div class="container">
                <!-- about partner -->
                <div class="row mb-3">
                    <div class="col-lg-6 col-xl-4 mb-3">
                        <h4 class="font-weight-600">About Partner Name</h4>
                        <pre>{{ partner.description }}</pre>
                    </div>
                    <div class="col-lg-6 col-xl-4 mb-3">
                        <ul class="contacts gray">
                            <li class="d-flex align-items-start">
                                <span
                                    class="icon bg-white d-flex justify-content-center align-items-center">
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="18"
                                        height="18"
                                        viewBox="0 0 15.542 15.542"
                                    >
                                        <path
                                            d="M14.976.746,11.82.018a.733.733,0,0,0-.835.422l-1.457,3.4a.727.727,0,0,0,.209.85l1.839,1.506A11.25,11.25,0,0,1,6.2,11.574L4.692,9.734a.728.728,0,0,0-.85-.209l-3.4,1.457a.737.737,0,0,0-.425.838l.729,3.157a.728.728,0,0,0,.71.565A14.083,14.083,0,0,0,15.541,1.457.728.728,0,0,0,14.976.746Z"
                                            transform="translate(0.001 0.001)"
                                            fill="#C6C6C6"
                                        />
                                    </svg>
                                </span>
                                <a :href="`tel:${ partner.phone }`" class="mx-3">
                                    {{ partner.phone }}
                                </a>
                            </li>
                            <li class="d-flex align-items-start">
                                <span
                                    class="
                                        icon
                                        bg-white
                                        d-flex
                                        justify-content-center
                                        align-items-center
                                    "
                                >
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="18"
                                        height="18"
                                        viewBox="0 0 10.803 17.285"
                                    >
                                        <path
                                            d="M9.183,0H1.62A1.621,1.621,0,0,0,0,1.62V15.665a1.621,1.621,0,0,0,1.62,1.62H9.183a1.621,1.621,0,0,0,1.62-1.62V1.62A1.621,1.621,0,0,0,9.183,0ZM5.4,16.2a1.08,1.08,0,1,1,1.08-1.08A1.079,1.079,0,0,1,5.4,16.2Z"
                                            fill="#C6C6C6"
                                        />
                                    </svg>
                                </span>
                                <a :href="`tel:${partner.mobile}`" class="mx-3">
                                    {{ partner.mobile }}
                                </a>
                            </li>
                            <li class="d-flex align-items-start">
                                <span
                                    class="icon bg-white d-flex justify-content-center align-items-center">
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="18"
                                        height="18"
                                        viewBox="0 0 15.542 15.542">
                                        <path
                                            d="M10.763,3a7.771,7.771,0,1,0,7.779,7.771A7.767,7.767,0,0,0,10.763,3Zm5.385,4.663H13.856A12.161,12.161,0,0,0,12.784,4.9,6.24,6.24,0,0,1,16.148,7.663ZM10.771,4.585a10.947,10.947,0,0,1,1.484,3.077H9.287A10.947,10.947,0,0,1,10.771,4.585Zm-6.015,7.74a6.079,6.079,0,0,1,0-3.108H7.383a12.834,12.834,0,0,0-.109,1.554,12.834,12.834,0,0,0,.109,1.554Zm.637,1.554H7.686a12.161,12.161,0,0,0,1.072,2.766A6.207,6.207,0,0,1,5.393,13.879ZM7.686,7.663H5.393A6.207,6.207,0,0,1,8.758,4.9,12.161,12.161,0,0,0,7.686,7.663Zm3.085,9.294a10.947,10.947,0,0,1-1.484-3.077h2.969A10.947,10.947,0,0,1,10.771,16.957Zm1.818-4.631H8.953a11.433,11.433,0,0,1-.124-1.554,11.334,11.334,0,0,1,.124-1.554h3.637a11.334,11.334,0,0,1,.124,1.554A11.433,11.433,0,0,1,12.589,12.325Zm.194,4.321a12.161,12.161,0,0,0,1.072-2.766h2.292a6.24,6.24,0,0,1-3.365,2.766Zm1.375-4.321a12.834,12.834,0,0,0,.109-1.554,12.834,12.834,0,0,0-.109-1.554h2.627a6.079,6.079,0,0,1,0,3.108Z"
                                            transform="translate(-3 -3)"
                                            fill="#C6C6C6"
                                        />
                                    </svg>
                                </span>
                                <a :href="partner.web_site" target="_blank" class="mx-3">
                                    {{ partner.web_site }}
                                </a>
                            </li>
                            <li
                                class="
                                    d-flex
                                    align-items-start
                                    justify-content-start
                                "
                            >
                                <span
                                    class="
                                        icon
                                        bg-white
                                        d-flex
                                        justify-content-center
                                        align-items-center
                                    "
                                >
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="18"
                                        height="18"
                                        viewBox="0 0 10.803 15.433"
                                    >
                                        <path
                                            d="M12.9,3A5.4,5.4,0,0,0,7.5,8.4c0,4.051,5.4,10.032,5.4,10.032s5.4-5.98,5.4-10.032A5.4,5.4,0,0,0,12.9,3Zm0,7.331A1.929,1.929,0,1,1,14.831,8.4,1.93,1.93,0,0,1,12.9,10.331Z"
                                            transform="translate(-7.5 -3)"
                                            fill="#C6C6C6"
                                        />
                                    </svg>
                                </span>
                                <span class="mx-3 address">
                                    {{ partner.address }}
                                </span>
                            </li>
                        </ul>
                    </div>
                    <div class="col-xl-4 mb-3">
                        <!-- <div class="map"></div> -->
                        <!-- <Map v-if="partner.latitude && partner.longitude" 
                        :lat="partner.latitude" :long="partner.longitude"/> -->
                    </div>
                </div>
                <!-- business unit -->
                <div v-if="isModulesExist"  class="business-unit d-flex align-items-center justify-content-between flex-column flex-md-row mb-3">
                    <div class="d-flex flex-wrap justify-content-center justify-content-md-start align-items-center mb-3 mb-md-0">
                        <button
                            @click="
                            currentUnit = null;
                            subUnit = null;
                            businessSubUnits = [];
                            getPartnerModules()"
                            class="unit btn p-0 px-1 m-1 mb-0 text-gray font-weight-normal whitespace-nowrap"
                            :class="{active : currentUnit == null}"
                            :disabled="currentUnit == null">
                            <small>All Units</small>
                        </button>
                        <button
                            @click="
                            currentUnit = unit.id;
                            subUnit == null;
                            businessSubUnits = [];
                            getPartnerModules()
                            subBusinessUnits(unit.id)"
                            v-for="(unit, index) in partner.unit"
                            :key="index"
                            class="unit btn p-0 px-1 m-1 mb-0 text-gray font-weight-normal whitespace-nowrap"
                            :class="{active : unit.id == currentUnit}"
                            :disabled="currentUnit == unit.id">
                            <small>{{ unit.name }}</small>
                        </button>
                    </div>
                    <div class="d-flex align-items-center flex-column flex-sm-row">
                        <select
                            v-model.number="filterBy" 
                            @change="getPartnerModules"
                            class="
                                mx-3
                                mb-2
                                mb-sm-1
                                px-1
                                form-control
                                select-lg
                                text-gray
                            ">
                            <option value="0">-- {{ $t('specialty') }} --</option>
                            <option v-for="(speclity, index) in specialtiesFilter" :key="index" :value="speclity.id">{{ speclity.name }}</option>
                        </select>
                        <select v-model.number="sortBy" 
                        @change="getPartnerModules"
                        class="px-1 mb-1 form-control select-lg text-gray">
                            <option selected value="0">Sort by</option>
                            <option value="ASC">ASC</option>
                            <option value="DESC">DESC</option>
                        </select>
                    </div>
                </div>
                <div class="separator"></div>
                <div 
                v-if="isModulesExist && businessSubUnits.length" 
                class="modules-featuers d-flex pb-1 mb-4 overflow-hidden">
                    <nav aria-label="Partner units">
                        <ul class="d-flex flex-wrap justify-content-center justify-content-md-start">
                            <li class="page-item mb-2">
                                <button @click="
                                subUnit = null;
                                getPartnerModules()"
                                :class="{ active: subUnit == null }"
                                class="btn sub-unit" href="javascript:;"
                                :disabled="subUnit == null">
                                    <small>All subunits</small>
                                </button>
                            </li>
                            <li v-for="sub in businessSubUnits" :key="sub.id" 
                            class="page-item mb-2">
                                <button @click="
                                subUnit = sub.id
                                getPartnerModules()"
                                :class="{ active: subUnit == sub.id }"
                                class="btn sub-unit" href="javascript:;">
                                    <small>{{ sub.name }}</small>
                                </button>
                            </li>
                        </ul>
                    </nav>
                </div>
                <div class="row">
                    <template v-if="moduleLoading">
                        <div v-for="(mod, index) in 4" :key="index" class="col-sm-6 col-lg-4 col-xl-3">
                            <div class="module-card mx-auto">
                                <div class="img mw-100 mb-3">
                                    <b-skeleton width="100%" height="9rem"></b-skeleton>
                                </div>
                                <ul class="d-flex flex-wrap justify-content-center justify-content-md-start align-items-center contains mb-3">
                                    <li v-for="(tag, index) in 3" :key="index">
                                        <div class="contains-item d-flex align-items-center">
                                            <b-skeleton width="3rem" height="1.2rem"></b-skeleton>
                                        </div>
                                    </li>
                                </ul>
                                <div class="progress mb-3">
                                    <b-skeleton width="45%" height="100%"></b-skeleton>
                                </div>
                                <h5 class="title text-capitalize">
                                    <b-skeleton width="8rem" height="1.1rem"></b-skeleton>
                                </h5>
                                <p class="sepciality text-capitalize">
                                    <b-skeleton width="6rem" height="0.8rem"></b-skeleton>
                                </p>
                                <div class="d-flex justify-content-between align-items-center mb-1">
                                    <div class="d-flex align-items-center">
                                        <b-skeleton class="img-circle" width="45px" height="45px"></b-skeleton>
                                        <small class="owner-name mx-2 text-capitalize">
                                            <b-skeleton width="5rem" height="0.7rem"></b-skeleton>
                                        </small>
                                    </div>
                                    <span class="price">
                                        <b-skeleton width="4rem" height="0.7rem"></b-skeleton>
                                    </span>
                                </div>
                            </div>
                        </div>
                    </template>
                    <template v-else-if="!moduleLoading && modules.length">
                        <div
                            v-for="module in modules"
                            :key="module.id"
                            class="col-sm-6 col-lg-4 col-xl-3">
                            <ModuleCard 
                            :cardData="module" 
                            @storedAsFavorite="getPartnerModules()"/>
                        </div>
                    </template>
                    <div v-else class="col-md-7 col-lg-6 mx-auto text-center mb-4">
                        <p v-if="isModulesExist" class="no-modules mx-auto py-2">
                            {{ $t('noResults') }}
                        </p>
                        <p v-else class="no-modules mx-auto py-2">
                            {{ $t('noModulesFound') }}
                        </p>
                    </div>
                </div>
                <!-- Pagination -->
                <div
                    v-if="lastPageID - 1 && !moduleLoading"
                    class="
                        d-flex
                        justify-content-center
                        py-4
                        mb-3
                        overflow-hidden
                    ">
                    <nav aria-label="Page navigation">
                        <ul class="pagination">
                            <li v-if="!isFirstPage" class="page-item">
                                <button 
                                @click="fetchPage('prev')"
                                class="page-link">
                                {{ $t('prev') }}
                                </button>
                            </li>
                            <li v-for="(page, index) in pagesLinks" :key="index"
                            class="page-item">
                                <button
                                    @click="fetchPage(page.id)"
                                    :class="[currentPage == page.id ? 'active' : '']"
                                    :disabled="currentPage == page.id"
                                    class="page-link">{{ page.id }}</button
                                >
                            </li>
                            <li v-if="!isLastPage" class="page-item">
                                <button 
                                @click="fetchPage('next')"
                                class="page-link">
                                {{ $t('next') }}
                                </button>
                            </li>
                        </ul>
                    </nav>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import ModuleCard from "@/components/ModuleCard";
// import Map from "@/components/Map";
import partnerHeadImg from "@/assets/imgs/quiz_img.png";

import { getPartner, getModules } from "@/API/partners";
import { 
    getSpecialty, 
    // getBusinessUnits,
    getSubBusinessUnit 
} from "@/API/utilites";
import pagination from "@/mixins/pagination";

export default {
    name: "Partner",
    mixins: [pagination],
    components: {
        ModuleCard,
        // Map
    },
    data() {
        return {
            loading: true,
            moduleLoading: true,
            isModulesExist: false,
            sortBy: 0,
            filterBy: 0,
            activeUnit: null,
            pagesLinks: [],
            currentPage: 1,
            isFirstPage: true,
            isLastPage: false,
            lastPageID: 1,
            partner: {},
            specialtiesFilter: [],
            modules: [],
            businessUnits: [],
            currentUnit: null,
            businessSubUnits: [],
            subUnit: null
        };
    },
    computed: {
        partnerID() {
            return parseInt(this.$route.params.id);
        },
        headerCoverStyle() {
            let img = partnerHeadImg
            if (this.partner.image_header) img = this.partner.image_header;

            return `background: url(${img}) center center no-repeat;background-size: cover;`;
        }
    },
    methods: {
        getCurrentPartner() {
            this.loading = true;
            getPartner(this.partnerID).then(res => {
                this.loading = false;

                if (res.status == 200) {
                    const data = res.data?.data;
                    console.log('partner', res.data);
                    this.partner = data;
                }

                if (res?.response?.status == 404) {
                    this.$router.push("/404");
                    return;
                }

                if (res?.response?.status == 500) {
                    this.$router.push("/500");
                    return;
                }
            });
        },
        getPartnerModules() {
            this.moduleLoading = true;
            const data = {
                ID: this.partnerID,
                page: this.currentPage,
            }
            if (this.filterBy) data.specility = this.filterBy;
            if (this.sortBy) data.sort = this.sortBy;
            if (this.currentUnit) data.unit = this.currentUnit;
            if (this.subUnit) data.subUnit = this.subUnit;

            getModules(data).then(res => {
                this.moduleLoading = false;
                if (res.status == 200) {
                    const data = res.data?.data;
                    // console.log('Module', data);

                    if (data?.module) {
                        this.modules = data.module;
                        if (data?.module?.length) {
                            this.isModulesExist = true;
                        }
                    };

                    if (data?.paginate) {
                        this.pagesLinks = this.pagination(data.paginate, this.currentPage).pagesLinks;
                        this.isFirstPage = this.pagination(data.paginate).isFirstPage;
                        this.isLastPage = this.pagination(data.paginate).isLastPage;
                        this.lastPageID = this.pagination(data.paginate).lastPageID;
                    }
                }

                if (res?.response?.status == 500) {
                    this.$router.push("/500");
                    return;
                }
            });
        },
        getSpecialtyFilter() {
            getSpecialty().then(res => {
                if (res.status == 200) {
                    const data = res.data?.data;
                    // console.log(data);
                    this.specialtiesFilter = data;
                }
            });
        },
        subBusinessUnits(unit) {
            getSubBusinessUnit(unit).then(res => {
                if (res.status == 200) {
                    const data = res.data?.data;
                    // console.log('sub', data);
                    if (data?.subBusinessUnit) this.businessSubUnits = data.subBusinessUnit;
                }
            });
        },
        fetchPage(id) {
            this.isFirstLoad = false;
            if (typeof(id) == 'number') {
                this.currentPage = id;
                this.getPartnerModules();
            } 

            if (id == 'prev' && !this.isFirstPage) {
                this.currentPage = this.currentPage - 1;
                this.getPartnerModules();
            }

            if (id == 'next' && !this.isLastPage) {
                this.currentPage = this.currentPage + 1;
                this.getPartnerModules();
            }
        },
    },
    mounted() {
        this.getCurrentPartner();
        this.getPartnerModules();
        this.getSpecialtyFilter();
    }
};
</script>

<style lang="scss" scoped>
    .sub-unit {
        display: inline-block;
        padding: 0.4rem 1rem;
        margin-left: 5px;
        margin-right: 5px;
        color: #3C3C3C;
        background-color: #F5F5F5;
        border-radius: 10px !important;
        border: 0;

        &.active {
            background-color: #007EC6 !important;
            color: #fff !important;
        }
        &:hover {
            color: #007EC6;
        }
    }

    .unit.btn {
        border: 0 !important;
        border-bottom: 2px solid transparent !important;
        &.active,
        &:hover {
            color: #007EC6 !important;
            font-weight: 600 !important;
            border-color: #007EC6 !important;
        }
    }
</style>